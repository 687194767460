import { render } from "./default.vue?vue&type=template&id=08aa49dd&scoped=true"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"

import "./default.vue?vue&type=style&index=0&id=08aa49dd&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-08aa49dd"

export default script

/* vue-cli-plugin-import-components */
import DefaultHeader from "@/components/layouts/default/DefaultHeader.vue";
script.components = Object.assign({}, { DefaultHeader }, script.components);if (script.options) { script.options.components = Object.assign({}, { DefaultHeader }, script.options.components);}