
import { defineComponent, computed } from 'vue';
import { MAIN_STYLE } from '@/configs';
import { initProvider, api } from '@/hooks';

export default defineComponent({
  setup() {
    initProvider();
    const isApiReady = computed(() => {
      return api.isReady;
    });
    return {
      MAIN_STYLE,
      isApiReady,
    };
  },
});
