
  <div class="min-h-screen app-container">
    <DefaultHeader />
    <main v-if="isApiReady" class="w-full">
      <RouterView />
    </main>
    <main v-else class="w-full h-96 flex items-center justify-center">
      <div class="loader"></div>
    </main>
  </div>
