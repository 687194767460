
  <div>
    <header class="default-header">
      <nav class="topbar">
        <div class="topbar__content">
          <h1 class="text-3xl font-bold text-white">Demo Polkadot</h1>
          <div>
            <el-dropdown v-if="account.address">
              <button
                class="
                  text-white
                  border border-solid border-white
                  px-6
                  py-2
                  focus:outline-none
                "
              >
                <span class="mr-2">
                  {{ account.name }}
                </span>

                <span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="acc in listAccounts"
                    :key="acc.address"
                    @click="changeAccount(acc)"
                  >
                    <i
                      v-show="acc.address === account.address"
                      class="el-icon-check"
                    ></i>
                    <span class="mr-2">
                      {{ acc.name }}
                    </span>
                    <span>{{ middleEllipsis(acc.address) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <button
              v-else
              @click="connectWallet"
              class="
                text-white
                border border-solid border-white
                px-6
                py-1
                focus:outline-none
              "
            >
              Connect Wallet
            </button>
          </div>
        </div>
      </nav>
    </header>
    <!-- <ModalConnectWallet /> -->
  </div>
