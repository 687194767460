
import { defineComponent, computed, onMounted, onBeforeUnmount } from 'vue';
import { middleEllipsis } from '@/utils';
import {
  account,
  listAccounts,
  connect,
  setUserWallet,
  IAccount,
  onChangeAccountConnected,
  subcribeAccounts,
  unsubscribe,
} from '@/hooks';

export default defineComponent({
  setup() {
    const connectWallet = () => {
      connect();
    };
    const changeAccount = (acc: IAccount) => {
      onChangeAccountConnected(acc);
    };
    onMounted(() => {
      if (account.value.address) {
        connect();
        subcribeAccounts();
      }
    });
    onBeforeUnmount(() => {
      if (unsubscribe.value) {
        unsubscribe.value();
      }
    });
    return {
      account,
      listAccounts,
      connectWallet,
      changeAccount,
      middleEllipsis,
    };
  },
});
